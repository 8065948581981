.textDialog {
  max-width: 90%;
  width: 90%;
  max-height: 80%;
  height: 80%;
}
.textDialogHalfTall {
  max-width: 60%;
  width: 60%;
  max-height: 80%;
  height: 80%;
}
.textDialogHalf {
  max-width: 60%;
  width: 60%;
  max-height: 50%;
  height: 50%;
}
